import React from 'react'
import './about.css'
import ME from '../../assets/oregon_state.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'




const About = () => {
  return (
    <section id="about">
      <h5>Get To Know </h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>5+ Years Data Analytics</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Education</h5>
              <li><small>Masters Informatics</small></li><br />
              <small>Bachelors Computer Science</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>School Projects</h5>
              <li><small>Website with backend database</small></li><br />
              <li><small>Website with Google Charts</small></li><br />
              <small>Website with API integration and Google Cloud</small>
            </article>
            
            <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="medium" data-theme="dark" data-type="VERTICAL" data-vanity="holly-lucas-45147b12" data-version="v1"><a class="badge-base__link LI-simple-link" href="https://www.linkedin.com/in/holly-lucas-45147b12?trk=profile-badge">Holly Lucas</a></div>

          </div>
          <p>
          Computer Science major with a healthcare background seeking an opportunity to break into tech.  
          My experience as a business owner/jack of all trades, masters level education in Informatics and varied 
          skillset including those in technical, data, visual arts and science make me an extremely well-rounded candidate.  
          My talent lies in streamlining technology and work processes.  I think outside of the box in every aspect of my 
          work and am a creative problem solver.  Expected to graduate with a B.S. in Computer Science from Oregon 
          State University in June of 2023. 
          </p>
          <a href ='#contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>



    </section>
  )
}

export default About