import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/stethescope_image.png'
import AVTR2 from '../../assets/analyst_image.png'
import AVTR3 from '../../assets/cdi_image.png'
import AVTR4 from '../../assets/avatar4.jpg'

//import swiper core an required modules
import { Navigation, Pagination, Scrollbar} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

//import swiper styles
import 'swiper/css'
import 'swiper/css/pagination';
import { RiNavigationFill } from 'react-icons/ri'




const data = [
  {
  avatar: AVTR3, 
  name: 'DeGloria Williams - 2018',
  review: 'Manager: Holly continued in her role of clinical documentation improvement specialist for evaluation year 2018. Her clinical\
  and coding knowledge combined with her tenacity for research contribute greatly to the team. She also uses her\
  informatics background to create reports and helpful aids to help identify opportunities to pick up conditions quickly.'
  },
  {
  avatar: AVTR1, 
  name: 'Brenda Urbanczyk - 2019',
  review: "Holly has become integral to the CDI team in terms of reporting, generating queries, and research. \
  She always goes above and beyond and is very flexible and dependable. \
  Holly routinely participated in discussions during coding/CDI weekly collaboration calls. This \
  collaboration was key to the success of the team this year with the presence of a consultant company \
  brought in to evaluate and potentially increase the CMI for the hospital. The team learned from the \
  exercise and immediately incorporated processes which resulted in minimal to no overall gain in the \
  CMI. Holly works closely with the coding team to ensure documentation integrity"
  },
  {
  avatar: AVTR2, 
  name: 'Brenda Urbanczyk - 2020',
  review: 'As a member of the CDI team, Holly continues to be integral to the team. In 2020 the team has\
  continued to query and communicate with providers and maintain the case mix index at ____ which is\
  0.06 increase from 2019. Holly continues to send numerous queries to providers for documentation\
  clarification related to diagnoses. She maintains a high level of productivity on the team. Holly\
  collaborates with coders and other team members frequently and shares information based on her\
  research of various topics. With the rapid changes in coding during COVID, Holly developed a flow chart\
  that was very beneficial to the team to help guide coding.\
  Other tasks that Holly performs are sending out readmission information to the inpatient nursing\
  administration, Quality, APPs, Managed Care, and Utilization Management. She builds and maintains all\
  queries utilized by coding and CDI, communicates weekly CMI updates, reviews and creates reports from\
  PHIS, and updates division slide presentations monthly. Holly is also very proficient in reporting which is\
  very beneficial.\
  Holly routinely attended and participated in team calls and attended mandatory department staff\
  Meetings'
  }
]





const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Employer Reviews</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[ Pagination, Navigation, Scrollbar]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true}}>
       {
        data.map(({avatar, name, review}, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
            <div className="client__avatar">
              <img src={avatar}/>
            </div>
            <h5 className="cleint__name">{name}</h5>
            <small className="cleint__review">
              {review}
            </small>  
          </SwiperSlide>
          )
        })
       }
      </Swiper>
      <h2>Swipe</h2>
    </section>
  )
}

export default Testimonials
